import { graphql, useStaticQuery } from "gatsby";
import { PropsWithChildren, ReactNode } from "react";

import Seo from "../Seo";
import SimplifiedFooter from "./SimplifiedFooter";
import SimplifiedHeader from "./SimplifiedHeader";

import * as styles from "./styles.module.scss";

interface Props {
  children: ReactNode | ReactNode[];
  seo: StoryblokMetaData;
  type: TemplateType;
}

export function SimplifiedLayout({
  children,
  seo,
  type,
}: PropsWithChildren<Props>): JSX.Element {
  const data = useStaticQuery<{
    header: StoryblokStory;
    footer: StoryblokStory;
  }>(graphql`
    query {
      header: storyblokEntry(field_component: { eq: "template_header" }) {
        content
      }
      footer: storyblokEntry(field_component: { eq: "template_footer" }) {
        content
      }
    }
  `);
  const { footer, header } = data;

  const parsedHeader = JSON.parse(header.content) as HeaderTemplate;
  const parsedFooter = JSON.parse(footer.content) as FooterTemplate;

  return (
    <>
      <Seo data={seo} ogType={type} />
      <SimplifiedHeader {...parsedHeader} />
      <main className={styles.wrapper}>{children}</main>
      <SimplifiedFooter {...parsedFooter} />
    </>
  );
}
